<template>
  <div>
    <div class="top_title">
      <div>公司与部门设置</div>
    </div>
    <div class="mid_title">
      <div class="Com_Dept_box">
        <div class="Com_Dept_box_top">
          <div class="Com_Dept_box_top_left">公司设置</div>
          <div class="Com_Dept_box_top_right">部门设置</div>
        </div>
        <div class="Com_Dept_box_mid">
          <div class="Com_Dept_box_mid_left">
            <el-tree
              :data="Companydata"
              :props="defaultProps"
              accordion
              node-key
              highlight-current
              @node-click="handleNodeClick"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span class="img">
                  <img
                    style="width: 15px"
                    v-show="node.id === nodeId"
                    src="../../assets/images/img1/del_img.png"
                    alt=""
                    @click="delItem(data)"
                  />
                  <img
                    style="width: 15px"
                    v-show="node.id === nodeId"
                    src="../../assets/images/img1/exit_img.png"
                    alt=""
                    @click="exitItem(data)"
                  />
                </span>
              </span>
            </el-tree>
          </div>
          <div class="Com_Dept_box_mid_right">
            <el-tree
              :data="deptdata"
              :props="defaultProps1"
              accordion
              @node-click="handleNodeClick1"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span class="img">
                  <img
                    style="width: 15px"
                    v-show="node.id === nodeId"
                    src="../../assets/images/img1/del_img.png"
                    alt=""
                    @click="delItem2(data)"
                  />
                  <img
                    style="width: 15px"
                    v-show="node.id === nodeId"
                    src="../../assets/images/img1/exit_img.png"
                    alt=""
                    @click="exitItem2(data)"
                  />
                </span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
      <div class="info" v-show="show === 1">
        <el-button type="primary" @click="onSubmit">新增公司</el-button>
        <el-button type="primary" @click="onSubmit1">新增部门</el-button>
        <div class="info_com">部门信息</div>
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item> </el-form-item>
          <el-form-item label="部门名称:"
            >{{ form.deptmentName }}
          </el-form-item>
          <el-form-item label="部门编号:"
            >{{ form.deptmentNo }}
          </el-form-item>
          <!-- <el-form-item label="部门代码:"></el-form-item> -->
          <!-- <el-form-item label="联系电话:"></el-form-item>
          <el-form-item label="部门地址:"></el-form-item>
          <el-form-item label="关联体系标准"></el-form-item> -->
          <el-form-item label="上级部门:"
            >{{ form.DeptmentName }}
          </el-form-item>
          <!-- <el-form-item label="负责人:"></el-form-item>
          <el-form-item label="邮政编码:"></el-form-item> -->
        </el-form>
      </div>
      <div class="info" v-show="show === 0">
        <el-button type="primary" @click="onSubmit">新增公司</el-button>
        <el-button type="primary" @click="onSubmit1">新增部门</el-button>
        <div class="info_com">公司信息</div>
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item> </el-form-item>
          <el-form-item label="公司名称:">{{ form.companyName }} </el-form-item>
          <!-- <el-form-item label="公司代码:"></el-form-item> -->
          <el-form-item label="联系电话:"
            >{{ form.companyPhone }}
          </el-form-item>
          <el-form-item label="公司地址:">{{
            form.companyDetailAddr
          }}</el-form-item>
          <el-form-item label="关联体系标准"
            >{{ form.companyName }}
          </el-form-item>
          <el-form-item label="上级公司:"
            >{{ form.upCompanyName }}
          </el-form-item>
          <!-- <el-form-item label="负责人:"> </el-form-item>
          <el-form-item label="邮政编码:"> </el-form-item> -->
        </el-form>
      </div>
    </div>
    <el-dialog title="新增公司" :visible.sync="dialogVisible" width="20%">
      <el-form label-width="80px" :model="formLabelAlign">
        <el-form-item label="公司名称">
          <el-input v-model="formLabelAlign.companyName"></el-input>
        </el-form-item>
        <el-form-item label="公司地址">
          <el-input v-model="formLabelAlign.companyDetailAddr"></el-input>
        </el-form-item>
        <el-form-item label="公司编号">
          <el-input v-model="formLabelAlign.companyNo"></el-input>
        </el-form-item>
        <el-form-item label="公司电话">
          <el-input v-model="formLabelAlign.companyPhone"></el-input>
        </el-form-item>
        <el-form-item label="上级公司">
          <el-select
            class="elselect"
            v-model="formLabelAlign.upCompanyId"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in Companydata1"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="负责人">
          <el-input v-model="formLabelAlign.type"></el-input>
        </el-form-item>
        <el-form-item label="邮政编码">
          <el-input v-model="formLabelAlign.types"></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addItem">保存并新增</el-button>
        <el-button type="primary" @click="addItem1">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改公司" :visible.sync="dialogVisibleexit" width="20%">
      <el-form label-width="80px" :model="formLabelAlign">
        <el-form-item label="公司名称">
          <el-input v-model="formLabelAlign.companyName"></el-input>
        </el-form-item>
        <el-form-item label="公司地址">
          <el-input v-model="formLabelAlign.companyDetailAddr"></el-input>
        </el-form-item>
        <el-form-item label="公司编号">
          <el-input v-model="formLabelAlign.companyNo"></el-input>
        </el-form-item>
        <el-form-item label="公司电话">
          <el-input v-model="formLabelAlign.companyPhone"></el-input>
        </el-form-item>
        <el-form-item label="上级公司">
          <el-select
            class="elselect"
            v-model="formLabelAlign.upCompanyId"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in Companydata1"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="负责人">
          <el-input v-model="formLabelAlign.type"></el-input>
        </el-form-item>
        <el-form-item label="邮政编码">
          <el-input v-model="formLabelAlign.type"></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exitItem1">确 定</el-button>
        <el-button @click="dialogVisibleexit = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="新增部门" :visible.sync="dialogVisible1" width="20%">
      <el-form label-width="80px" :model="formLabelAlign1">
        <el-form-item label="部门名称">
          <el-input v-model="formLabelAlign1.deptmentName"></el-input>
        </el-form-item>
        <el-form-item label="部门编号">
          <el-input v-model="formLabelAlign1.deptmentNo"></el-input>
        </el-form-item>
        <el-form-item label="上级公司">
          <el-select
            class="elselect"
            v-model="formLabelAlign1.upCompanyId"
            placeholder="请选择"
            @change="FindDept"
            clearable
          >
            <el-option
              v-for="item in Companydata1"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级部门">
          <el-select
            class="elselect"
            v-model="formLabelAlign1.upDeptmentId"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in deptdata1"
              :key="item.value"
              :label="item.deptmentName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="负责人">
          <el-input v-model="formLabelAlign1.type"></el-input>
        </el-form-item>
        <el-form-item label="邮政编码">
          <el-input v-model="formLabelAlign1.type"></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addDeptItem">保存并新增</el-button>
        <el-button type="primary" @click="addDeptItem1">确 定</el-button>
        <el-button @click="dialogVisible1 = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改部门" :visible.sync="dialogVisible1exit" width="20%">
      <el-form label-width="80px" :model="formLabelAlign1">
        <el-form-item label="部门名称">
          <el-input v-model="formLabelAlign1.deptmentName"></el-input>
        </el-form-item>
        <el-form-item label="部门编号">
          <el-input v-model="formLabelAlign1.deptmentNo"></el-input>
        </el-form-item>
        <el-form-item label="上级部门">
          <el-select
            class="elselect"
            v-model="formLabelAlign1.upDeptmentId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in deptdata1"
              :key="item.value"
              :label="item.deptmentName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级公司">
          <el-select
            class="elselect"
            v-model="formLabelAlign1.upCompanyId"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in Companydata1"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="负责人">
          <el-input v-model="formLabelAlign1.type"></el-input>
        </el-form-item>
        <el-form-item label="邮政编码">
          <el-input v-model="formLabelAlign1.type"></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toexitItem">确 定</el-button>
        <el-button @click="dialogVisible1exit = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post, put } from '../../api/http'

export default {
  data() {
    return {
      Companydata: [],
      Companydata1: [],
      deptdata: [],
      deptdata1: [],
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleexit: false,
      dialogVisible1exit: false,
      defaultProps: {
        children: 'companylist',
        label: 'companyName',
      },
      defaultProps1: {
        children: 'deptlist',
        label: 'deptmentName',
      },
      show: 0,
      form: {},
      nodeId: '',
      formLabelAlign1: {
        deptmentNo: '',
        deptmentName: '',
        upCompanyId: '',
        upDeptmentId: '',
        isDelete: 0,
      },
      formLabelAlign: {
        id: 0,
        companyNo: '',
        companyName: '',
        companyDetailAddr: '',
        companyPhone: '',
        upCompanyId: '',
        isDelete: 0,
      },
    }
  },
  filters: {
    handleCpy(e) {
      this.Companydata1.forEach((i) => {
        if (i.id === e) {
          return i.companyName
        }
      })
    },
  },
  mounted() {
    this.getComdata()
    this.Companydata1 = this.$store.state.companyList
  },
  methods: {
    onSubmit() {
      this.formLabelAlign.companyNo = ''
      this.formLabelAlign.companyName = ''
      this.formLabelAlign.companyDetailAddr = ''
      this.formLabelAlign.companyPhone = ''
      this.formLabelAlign.upCompanyId = ''
      this.dialogVisible = true
    },
    onSubmit1() {
      this.formLabelAlign1.deptmentNo = ''
      this.formLabelAlign1.deptmentName = ''
      this.formLabelAlign1.upCompanyId = ''
      this.formLabelAlign1.upDeptmentId = ''
      this.dialogVisible1 = true
    },
    getComdata() {
      get('/api/Company/GetTree').then((res) => {
        this.Companydata = res.data
      })
    },
    getCompy() {
      get('/api/Company/GetAll').then((resp) => {
        if (resp.code == 200) {
          this.$store.dispatch('companyList', resp.data)
          sessionStorage.setItem('companyList', JSON.stringify(resp.data))
          this.Companydata1 = resp.data
        }
      })
    },
    getUserCompy(){
      get('/api/Company/GetAllByUser').then((resp) => {
          if (resp.code == 200) {
            this.$store.dispatch('companyUserList', resp.data)
            sessionStorage.setItem('companyUserList', JSON.stringify(resp.data))
          } 
        })
    },
    handleNodeClick(e, node) {
      this.show = 0
      this.form = e
      this.Companydata1.forEach((i) => {
        if (i.id === e.upCompanyId) {
          e.upCompanyName = i.companyName
        }
      })
      this.nodeId = node.id
      get('/api/Department/GetTree?CompId=' + e.id).then((res) => {
        this.deptdata = res.data
      })
    },
    addItem() {
      if (this.formLabelAlign.upCompanyId === '') {
        this.formLabelAlign.upCompanyId = 0
      }
      post('/api/Company', this.formLabelAlign).then((res) => {
        this.$message({
          type: 'success',
          message: res.message,
        })
        this.onSubmit()
        this.getComdata()
        this.getCompy()
        this.getUserCompy()
      })
    },
    delItem(e) {
      this.$confirm('此操作将永久删除该公司, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          console.log(e)
          deleteMethod('/api/Company/' + e.id).then((res) => {
            this.$message({
              type: 'success',
              message: res.message,
            })
            this.getComdata()
            this.getCompy()
            this.getUserCompy()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    exitItem(e) {
      this.formLabelAlign = e
      this.formLabelAlign.upCompanyId = this.formLabelAlign.upCompanyId?this.formLabelAlign.upCompanyId:''
      this.dialogVisibleexit = true
    },
    exitItem1() {
      this.formLabelAlign.upCompanyId = this.formLabelAlign.upCompanyId?this.formLabelAlign.upCompanyId:0
      put('/api/Company/', this.formLabelAlign).then((res) => {
        this.$message({
          type: 'success',
          message: res.message,
        })
        this.dialogVisibleexit = false
        this.getComdata()
        this.getCompy()
        this.getUserCompy()
      })
    },
    addDeptItem() {
      if (this.formLabelAlign1.upDeptmentId === '') {
        this.formLabelAlign1.upDeptmentId = 0
      }
      post('/api/Department', this.formLabelAlign1)
        .then((res) => {
          console.log(res)
          this.$message({
            type: 'success',
            message: res.message,
          })
        })
        .then(() => {
          get('/api/Department/GetTree?CompId=' + this.form.id).then((res) => {
            this.deptdata = res.data
          })
        })
      this.onSubmit1()
    },
    addDeptItem1() {
      if (this.formLabelAlign1.upDeptmentId === '') {
        this.formLabelAlign1.upDeptmentId = 0
      }
      post('/api/Department', this.formLabelAlign1)
        .then((res) => {
          console.log(res)
          this.$message({
            type: 'success',
            message: res.message,
          })
        })
        .then(() => {
          get('/api/Department/GetTree?CompId=' + this.form.id).then((res) => {
            this.deptdata = res.data
          })
        })

      this.dialogVisible1 = false
    },
    exitItem2(e) {
      this.formLabelAlign1 = e
      this.formLabelAlign1.upDeptmentId = this.formLabelAlign1.upDeptmentId?this.formLabelAlign1.upDeptmentId:''
      get('/api/Department/GetNoTree?CompId=' + e.upCompanyId).then((res) => {
        this.deptdata1 = res.data
      })
      this.dialogVisible1exit = true
    },
    delItem2(e) {
      this.$confirm('此操作将永久删除该部门, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          console.log(e)
          deleteMethod('/api/Department/' + e.id).then((res) => {
            this.$message({
              type: 'success',
              message: res.message,
            })
            get('/api/Department/GetTree?CompId=' + e.upCompanyId).then(
              (res) => {
                this.deptdata = res.data
              }
            )
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    FindDept(e) {
      get('/api/Department/GetNoTree?CompId=' + e).then((res) => {
        this.deptdata1 = res.data
      })
    },
    toexitItem() {
      this.formLabelAlign1.upDeptmentId = this.formLabelAlign1.upDeptmentId?this.formLabelAlign1.upDeptmentId:0
      put('/api/Department', this.formLabelAlign1)
        .then((res) => {
          this.$message({
            type: 'success',
            message: res.message,
          })
        })
        .then(() => {
          get(
            '/api/Department/GetTree?CompId=' + this.formLabelAlign1.upCompanyId
          ).then((res) => {
            this.deptdata = res.data
          })
          this.dialogVisible1exit = false
        })
    },
    addItem1() {
      if (this.formLabelAlign.upCompanyId === '') {
        this.formLabelAlign.upCompanyId = 0
      }
      post('/api/Company', this.formLabelAlign).then((res) => {
        console.log(res)
        this.$message({
          type: 'success',
          message: res.message,
        })
        this.dialogVisible = false
        this.getComdata()
        this.getCompy()
        this.getUserCompy()
      })
    },
    handleNodeClick1(e, node) {
      this.show = 1
      this.nodeId = node.id
      this.form = e
      this.deptdata.forEach((i) => {
        if (i.id === e.upDeptmentId) {
          e.DeptmentName = i.deptmentName
        }
      })
      console.log(this.form)
    },
  },
}
</script>

<style scoped>
@import '../../assets/style/Com_Dept.css';
</style>
